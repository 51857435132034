<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center">
          <v-col cols="12" md="6" xl="4">
            <div class="d-flex justify-center">
              <v-img
                class="global-logoimg mt-6"
                src="@/assets/logo-mclick-min.png"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-1"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <v-flex lg12 md12 class="pr-12 pl-6">
              <h4 class="text-h4 my-6 text-center">
                Recuperación de contraseña
              </h4>
            </v-flex>

            <v-card>
              <v-card-title>
                <span class="global-large">¿Olvidaste tu contraseña? </span>
                <br />
              </v-card-title>
              <v-card-text>
                <v-form ref="recoverpass">
                  <p class="caption">
                    Ingrese el usuario para recuperar la contraseña.
                  </p>
                  <v-text-field
                    label="Usuario"
                    v-model="username"
                    v-mask="rutMask"
                    :rules="rules"
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  large
                  :to="{ name: 'Login' }"
                  :disabled="loadingstatus"
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  {{ mobile ? "" : "Regresar" }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="recover()"
                  large
                  :loading="loadingstatus"
                >
                  Recuperar {{ mobile ? "" : "contraseña" }}
                  <v-icon right>mdi-lock-check-outline</v-icon>
                </v-btn>

                <v-slide-y-transition>
                  <v-alert
                    v-model="error"
                    type="error"
                    outlined
                    dismissible
                    close
                    class="mt-3"
                  >
                    {{ errormsg }}
                  </v-alert>
                </v-slide-y-transition>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <Modal
          :open="usermodal"
          @closeDialogThree="closeDialogThree"
          :useremail="useremail"
        />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Modal from "@/components/Modal/Modal-Change-Password";
const Urlconvenio =
  process.env.VUE_APP_CONVENIO + "." + process.env.VUE_APP_DOMAIN;

import { rutMask } from "@/store/resources/masks.js";
import { validarRutFormat, validarRut } from "@/store/resources/validate.js";
import { bffHeaders } from "@/helpers/headers.js";

import axios from "axios";
export default {
  components: {
    Modal,
  },
  data: () => ({
    username: "",
    openthree: false,
    snackbar: null,
    usermodal: false,
    loadingstatus: false,
    errormsg: "",
    error: false,
    useremail: "",
    rules: [
      (value) => !!value || "Usuario es un campo requerido.",
      (v) => validarRutFormat(v) || "Formato de RUT invalido",
      (v) => validarRut(v) || "No se ha logrado reconocer el RUT",
    ],
  }),
  methods: {
    recover() {
      if (this.$refs.recoverpass.validate() && this.username) {
        this.loadingstatus = true;
        this.error = false;
        axios({
          url: "/ventas/usuarios/recuperar-clave",
          method: "POST",
          data: {
            usuario: this.username.replace(/[^A-Z0-9]/gi, ""),
            convenio: Urlconvenio,
            modulo: "negocios",
          },
          headers: bffHeaders,
        })
          .then((resp) => {
            this.usermodal = true;
            this.useremail = resp.data.data.correo;
          })
          .catch((err) => {
            this.errormsg =
              err.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
              "Ha ocurrido un error al intentar recuperar la contraseña.";
            this.error = true;
          })
          .finally(() => (this.loadingstatus = false));
      }
    },
    closeDialogThree() {
      this.openthree = !this.openthree;
    },
  },
  computed: {
    rutMask() {
      return rutMask;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
