<template>
  <div class="text-center">
    <v-dialog v-model="open" width="880px" height="327px">
      <v-card class="global-modalCard3">
        <v-layout fill-height class="pa-0">
          <v-flex class="text-left pl-6 pt-10 mt-10 ml-10">
            <p class="global-ptxt">
              <span class="global-bigtext">¡Revisa tu correo! </span><br />
              <span class="global-small"
                >Te enviamos las instrucciones para recuperar<br />
                tu contraseña a
                <span :style="{ color: titleColor }">{{
                  useremail
                }}</span></span
              >
            </p>
            <v-spacer></v-spacer>
          </v-flex>

          <v-flex v-if="TEST" class="text-left pl-6 pt-10 mt-10 ml-10">
            <h1 class="global-titleStyle9">
              su contrasena ha sido cambiada con exito!
            </h1>
            <v-spacer></v-spacer>

            <h4 class="mt-12 global-subtitleStyle5">
              Favor de ingresar nuevamente al sistema <br />
              con la contrasena nueva
            </h4>
          </v-flex>

          <v-flex class="d-flex align-center justify-end">
            <div
              id="ComputerSvg"
              style="align-items: center; justify-content: center"
            >
              <v-flex class="mb-5 text-end justify-end"> </v-flex>
              <v-img
                class="global-image5"
                max-width="160px"
                src="@/assets/modalImages/pana.png"
              />
              <div class="global-buttons mt-7 mb-7 d-flex">
                <btnprincipal
                  color="#0077c8"
                  label="Entendido"
                  v-on:click.native="close"
                  class="mr-6 pr-6 pl-6"
                  width="153"
                  height="45"
                />
              </div>
            </div>

            <v-img
              max-width="450px"
              width="430"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import btnprincipal from "@/components/Button/Btn-Principal.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: true,
    TEST: false,
  }),
  props: ["open", "useremail"],
  components: {
    btnprincipal,
  },
  methods: {
    close() {
      this.$store.dispatch("closeDialog").then(this.$router.push("/login"));
    },
  },
  computed: {
    ...mapGetters("Users", ["getTituloColor"]),
    titleColor() {
      if (this.getTituloColor != null) {
        return this.getTituloColor;
      } else {
        return "#0077c8";
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
h4 {
  z-index: 10;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
}
#ComputerSvg {
  z-index: 10;
}
:deep .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  /* z-index: inherit; */
  /* box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%); */
}
</style>
